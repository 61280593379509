import request from '../utils/request'

export function createRoomToken(room,userId,type) {
    return request.post('./index.php', {
        function: 'createRoomToken',
        room,userId,type
    })
}

export function loginByEducator(email,pwd) {
    return request.post('./index.php', {
        function: 'loginByEducator',
        email,pwd
    })
}

export function loginByEmployee(email,pwd) {
    return request.post('./index.php', {
        function: 'loginByEmployee',
        email,pwd
    })
}

export function loginByStudent(project_code,student_email,student_pwd) {
    return request.post('./index.php', {
        function: 'loginByStudent',
        project_code, student_email,student_pwd
    })
}

export function getUserNames() {
    return request.post('./index.php', {
        function: 'getUserNames'
    })
}

export function getMyEvents(student_id) {
    return request.post('./index.php', {
        function: 'getMyEvents',
        student_id
    })
}

export function getEventsByEducator(educator_id) {
    return request.post('./index.php', {
        function: 'getEventsByEducator',
        educator_id
    })
}

export function getMyZhiboRooms(student_id) {
    return request.post('./index.php', {
        function: 'getMyZhiboRooms',
        student_id
    })
}

export function getExerciseTemplateByStudent(template_id,user_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudent',
        template_id,user_id
    })
}

//废弃，不要用了
export function uploadAnswers(answers,student_id,template_id) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        answers,student_id,template_id
    })
}
export function uploadAnswersV1(project_code,answers,student_id,template_id,app_id,ip) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        project_code, answers,student_id,template_id,app_id,ip
    })
}

export function changeVisibleLog(student_id,project_code,template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'changeVisibleLog',
        student_id,project_code,template_id
    })
}

export function getTestVisibleChangeCount(template_id,student_id,project_code) {
    return request.post('/RE_Arena/index.php', {
        function: 'getTestVisibleChangeCount',
        template_id,student_id,project_code
    })
}

export function submitAnswer(question_id,template_id,choice,student_id,ip,test_event,project_code) {
    return request.post('./index.php', {
        function: 'submitAnswer',
        question_id,template_id,choice,student_id,ip,test_event,project_code
    })
}
export function uploadAnswersV2(project_code,student_id,template_id,app_id,ip,test_event,test_type) {
  return request.post('./index.php', {
      function: 'uploadAnswersByLogs',
      project_code,student_id,template_id,app_id,ip,test_event,test_type
  })
}
export function uploadAnswersV3(project_code,student_id,template_id,app_id,ip,test_event,test_type,answers) {
    return request.post('./index.php', {
        function: 'uploadAnswersByLogsNew',
        project_code,student_id,template_id,app_id,ip,test_event,test_type,answers
    })
}
export function saveAudioRecord(user_id,section_id,template_id) {
    return request.post('./index.php', {
        function: 'saveAudioRecord',
        user_id,section_id,template_id
    })
}

export function getUser(user_id,user_type) {
    return request.post('./index.php', {
        function: 'getUser',
        user_id,user_type
    })
}

export function updateUserAvatar(user_id,avatar_url) {
    return request.post('./index.php', {
        function: 'updateUserAvatar',
        user_id,avatar_url
    })
}

export function getMyMessages(id,project_code) {
    return request.post('./index.php', {
        function: 'getMyMessages',
        id,project_code
    })
}

export function sendMessage(student_id,type,sender_type,content,project_code) {
    return request.post('./index.php', {
        function: 'sendMessage',
        student_id,type,sender_type,content,project_code
    })
}





export function uploadPic(param) {
    return request.post('./index.php', param)
}

export function listRoomUsers(room) {
    return request.post('./index.php', {
        function: 'listRoomUsers',
        room
    })
}

export function getSchoolScoresByEvent(event) {
    return request.post('./index.php', {
        function: 'getSchoolScoresByEvent',
        event
    })
}

export function getAllZhiboEvents() {
    return request.post('./index.php', {
        function: 'getAllZhiboEvents'
    })
}

export function getAllExams() {
    return request.post('./index.php', {
        function: 'getAllExams'
    })
}

export function getApplicationsByEvent(event) {
    return request.post('./index.php', {
        function: 'getApplicationsByEvent',
        event
    })
}

export function getScoresByEvent(event) {
    return request.post('./index.php', {
        function: 'getScoresByEvent',
        event
    })
}

export function getScoresSelf(event,user_id) {
    return request.post('./index.php', {
        function: 'getScoresSelf',
        event,user_id
    })
}

export function checkTestLinkOpen(student_id,name) {
    return request.post('./index.php', {
        function: 'checkTestLinkOpen',
        student_id,name
    })
}
export function checkZhiboOpen(student_id,roomId) {
    return request.post('./index.php', {
        function: 'checkZhiboOpen',
        student_id,roomId
    })
}

export function checkPeixun() {
    return request.post('./index.php', {
        function: 'checkPeixun'
    })
}

export function getApplicationsByRoom(roomId) {
    return request.post('./index.php', {
        function: 'getApplicationsByRoom',
        roomId
    })
}
//废弃了
export function uploadTimeStamp(student_id,template_id,stamp) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTimeStamp',
        student_id,template_id,stamp
    })
}

export function uploadTimeStampV1(student_id,template_id,stamp,project_code) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTimeStamp',
        student_id,template_id,stamp,project_code
    })
}

export function getTimeStamp(student_id,template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'getTimeStamp',
        student_id,template_id
    })
}

export function getGroupAwards(project_code,event) {
    return request.post('./index.php', {
        function: 'getGroupAwards',
        project_code,event
    })
}

export function getAwards(project_code,application_event) {
    return request.post('./index.php', {
        function: 'getAwards',
        project_code,application_event
    })
}


export function getMyAward(student_id) {
    return request.post('./index.php', {
        function: 'getMyAward',
        student_id
    })
}

export function getSessionList(project_code) {
    return request.post('./index.php', {
        function: 'getSessionList',
        project_code
    })
}
export function getSessionEvents(project_code,session,student_id) {
    return request.post('./index.php', {
        function: 'getSessionEvents',
        project_code,
        session,
        student_id

    })
}
export function getExerciseTestTemplate(event,project_code,type) {
    return request.post('./index.php', {
        function: 'getExerciseTestTemplate',
        event,
        project_code,
        type
    })
}
export function uploadExamShot(student_id,project_code,template_id,camera_pic,screen_pic) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadExamShot',
        student_id,project_code,template_id,camera_pic,screen_pic
    })
}


export function findMyTranscript(student_id,project_code) {
    return request.post('./index.php', {
        function: 'findMyTranscript',
        student_id,
        project_code
    })
}

export function getEventScores(event,project_code) {
    return request.post('./index.php', {
        function: 'getEventScores',
        event,
        project_code
    })
}


export function getExerciseTemplateByStudentReview(template_id,student_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudentReview',
        template_id,
        student_id
    })
}

export function checkIsUploadTest(template_id,student_id) {
    return request.post('./index.php', {
        function: 'checkIsUploadTest',
        template_id,
        student_id
    })
}

export function initExamShotTimePoint(student_id,project_code,template_id) {
    return request.post('/RE_Arena/index.php', {
        function: 'initExamShotTimePoint',
        student_id,project_code,template_id
    })
}

export function getHomeAnnouncements() {
    return request.post('./index.php', {
        function: 'getHomeAnnouncements'
    })
}

export function uploadExamShotNew(param) {
    return request.post('/RE_Arena/index.php', 
       param
    )
}

export function checkTranscriptOpen(project_code) {
    return request.post('./index.php', {
        function: 'checkTranscriptOpen',
        project_code
    })
}

export function test(project_code) {
    return request.post('./index.php', {
        function: 'test',
        project_code
    })
}
export function uploadTestHeartBeat(student_id,template_id,status) {
    return request.post('/RE_Arena/index.php', {
        function: 'uploadTestHeartBeat',
        student_id,template_id,status
    })
}
export function studentSignWithTemplate(student_id,template_id,project_code) {
    return request.post('./index.php', {
        function: 'studentSignWithTemplate',
        student_id,template_id,project_code
    })
}

export function uploadStudentIdCard(student_id,template_id,project_code,pic_url) {
    return request.post('./index.php', {
        function: 'uploadStudentIdCard',
        student_id,template_id,project_code,pic_url
    })
}
export function getStudentIdCard(student_id,template_id,project_code) {
    return request.post('./index.php', {
        function: 'getStudentIdCard',
        student_id,template_id,project_code
    })
}


export function getStudentAwards(project_code,student_id) {
    return request.post('./index.php', {
        function: 'getStudentAwards',
        project_code,student_id
    })
}

export function getStudentTranscripts(project,student_id) {
    return request.post('./index.php', {
        function: 'getStudentTranscripts',
        project,student_id
    })
}




export function checkUserInvigilate(project_code,student_id) {
    return request.post('./index.php', {
        function: 'checkUserInvigilate',
        project_code,student_id
    })
}


export function getPresentationRoomsByApplication (student_id,project_code) {
    return request.post('./index.php',{
      function: 'getPresentationRoomsByApplication',
      student_id,
      project_code
    })
  }

  export function checkPresentationRoomOpen (room_id) {
    return request.post('./index.php',{
      function: 'checkPresentationRoomOpen',
      room_id
    })
  }
  
  export function getPresentationInfo(student_id,type) {
    return request.post('./index.php', {
      function: 'getPresentationInfo',
      student_id,
      type
    })
  }
  export function createPresentationRoomToken (roomId,userId) {
    return request.post('./index.php', {
      function: 'createPresentationRoomToken',
      roomId,userId
    })
  }

    export function getExamReadyTime() {
    return request.post("./index.php", {
        function: "getExamReadyTime",
    });
    }